(function ($) {

    $.fn.extend({
        initProgressBar: function () {

            if ($(this).data('ProgressBar'))
                return;

            var container = $(this);

            var color = container.data('color');
            var trailColor = container.data('trail-color');
            var strokeWidth = container.data('stroke-width');
            var trailWidth = container.data('trail-width');
            var duration = container.data('duration');
            var textValue = container.data('text-value');
            var strokeColor = container.data('stroke-color');
            var fontSize = container.data('font-size');
            var fontWeight = container.data('font-weight');
            var easing = container.data('easing');

            var bar = new ProgressBar.Circle(container[0], {
                color: color || '#003567',
                trailColor: trailColor || '#00FFFFFF',
                // This has to be the same size as the maximum width to
                // prevent clipping
                trailWidth: trailWidth || 2,
                strokeWidth: strokeWidth || 4,
                easing: easing || 'easeInOut',
                duration: duration || 2000,
                text: {
                    value: textValue || '0'
                },
                from: {color: trailColor || '#00FFFFFF', width: trailWidth || 2},
                to: {color: strokeColor || '#00FFFFFF', width: strokeWidth || 4},
            });

            bar.text.style.fontSize = fontSize || '1rem';
            bar.text.style.fontWeight = fontWeight || '700';

            $(this).data('ProgressBar', bar);
        },

        animateProgressBar: function (value, max) {

            value = value || $(this).data('count');
            const render = $(this).data('render');

            if (isNaN(Number(value)))
                return;

            var cLocale;

            if (typeof checkLocale === "function") {
                var cLocale = locale;
            } else {
                console.warn("Progess Bar Lys component need Lys localization component to get correct localization properties. Fallback to 'it-IT'");
                cLocale = 'it-IT';
            }

            var bar = $(this).data('ProgressBar');

            bar.val = Number(value) || 0;
            bar.max = Number(max);

            var step = function (state, bar) {
                bar.path.setAttribute('stroke', state.color);
                bar.path.setAttribute('stroke-width', state.width);

                var curr;

                if (isNaN(bar.max))
                    curr = bar.value() * bar.val;
                else
                    curr = bar.value() * bar.max;

                if (render) {
                    var x = eval(render);
                    if (typeof x == 'function') {
                        curr = x(curr);
                    }
                }
                else
                    curr = Number(curr).toLocaleString(cLocale);

                bar.setText(curr);

            };

            var val = (!isNaN(bar.max)) ? bar.val / bar.max : 1;

            bar.animate(val, {step: step});
        }
    });

    $(document).ready(function () {
        $('*[data-progress-bar]').each(function () {
            $(this).initProgressBar();
            $(this).animateProgressBar();
        });
    });

})(jQuery);


(function ($) {
    $.fn.extend({

            initSelect2Remote: function () {

                var select2 = $(this);

                var options = {
                    ajax: {
                        url: function () {
                            var route = select2.data("ajax--route");
                            var params = select2.data("ajax--params");

                            return Routing.generate(route, params);
                        },
                        data: function (params) {
                            return {
                                id: params.id,
                                name: params.term
                            };
                        },
                        dataType: 'json',
                        delay: 250,
                        data: function (params) {
                            return {
                                name: params.term
                            };
                        },
                        processResults: function (data) {
                            var rows = data;
                            var idField = select2.data("ajax--id");
                            var textField = select2.data("ajax--text");

                            rows = $.map(rows, function (row) {
                                row.id = row[idField];
                                row.text = row[textField];
                                return row;
                            });
                            return {
                                results: rows
                            };
                        }
                    },
                    escapeMarkup: function (markup) {
                        return markup;
                    },
                    minimumInputLength: 0,
                    minimumResultsForSearch: 20
                }


                if (!select2.attr('required')) {
                    if (!select2.attr('multiple'))
                        options.allowClear = true;
                }

                if (select2.data('placeholder')) {
                    options.placeholder = function () {
                        $(this).data('placeholder');
                    }
                }
                else {
                    options.placeholder = {
                        id: "",
                        placeholder: "Leave blank to ..."
                    }
                }

                select2.select2(options);

                // Set initial value based on selected option HTML element
                var values = [];

                select2.find('option:selected').each(function () {
                    values.push(this.value);
                });

                select2.val(values).trigger('change');

            }

        }
    );

    $(document).ready(function () {
        $('.select2-remote').each(function () {
            $(this).initSelect2Remote();
        });
    });

})
(jQuery);

import 'bootstrap/dist/css/bootstrap.css';
import 'datatables.net-src/css/dataTables.bootstrap4.scss';
import 'fullcalendar/dist/fullcalendar.css';
import 'fullcalendar-scheduler/dist/scheduler.css';
import '../sass/main.scss';

/* JS */
import 'jquery';
import 'jquery-ui';
import 'bootstrap';
import 'select2';
import 'fullcalendar/dist/locale/it';
import './components/lys.localization.js';
import './components/lys.datetimepicker.js';
import './components/lys.deletemodal.js';
import './components/lys.template.js';
import './components/lys.select2.remote.js';
import './components/lys.menu.js';
import './components/lys.panel.js';
import './components/lys.tooltip.js';
import './components/lys.progressbar.js';

import * as FontAwesome from './components/fontawesome';

function toggleField(field, value) {
    field = $(field);
    if (value) {
        field.attr('required', 'required');
        field.removeAttr('disabled');
        field.siblings('label').addClass('required');
        field.closest('div[class|="col"]').removeClass('hidden');
    } else {
        field.removeAttr('required');
        field.attr('disabled', 'disabled');
        field.siblings('label').removeClass('required');
        field.closest('div[class|="col"]').addClass('hidden');
    }
}

function getOptions(route, field) {
    $.get(route, function (data) {
        var options = $.map(data, function (opts) {
            return $("<option>", {
                value: opts,
                html: opts
            });
        });

        var value = $(field).val();

        var optionHTML = "";

        for (var i = 0; i < options.length; i++) {
            var option = options[i];
            if (value && option.val() === value) {
                option.attr('selected', '');
            }
            optionHTML += option[0].outerHTML;
        }

        const placeholder = $("<option>", {
            html: ""
        })[0].outerHTML;

        optionHTML = placeholder + optionHTML;

        $(field).empty().append(optionHTML);
        $(field).trigger('change');

    });
}

function updateFieldOptions(field) {

    if (!field.length)
        return;

    field = $(field)[0];
    const courseField = $('select[name="cons_isee[course]"]');

    switch (field.name) {
        case 'cons_isee[school]':
            var course = courseField.val();
            var route = Routing.generate('get_schools', {'course': course});
            getOptions(route, field);
            toggleField(field, true);
            break;
        case 'cons_isee[enrollmentYear]':
            course = courseField.val();
            route = Routing.generate('get_enrollment_years', {
                'course': course,
                'enrollment': _enrollment,
            });
            getOptions(route, field);
            toggleField(field, true);
            break;
        case 'cons_isee[cfaRange]':
            var type = $('select[name="cons_isee[type]"]').val();
            route = Routing.generate('get_cfa_ranges', {'type': type});
            getOptions(route, field);
            toggleField(field, true);
            break;

    }

}

$(document).ready(function () {

    const form = $('form[name="cons_isee"]');

    const fields = form.find('select');
    for (var i = 0; i < fields.length; i++) {
        var field = fields[i];
        if ($(field).is(":visible"))
            updateFieldOptions(field);
    }

    $('input[name="cons_isee[noIsee]"]').trigger('change');

    $('select[name="cons_isee[course]"]').change(function () {
        const course = this.value;

        var schoolField = $('select[name="cons_isee[school]"]');
        var typeField = $('select[name="cons_isee[type]"]');

        schoolField.val("").trigger('change');
        typeField.val("").trigger('change');

        if (course) {
            updateFieldOptions(schoolField);
            toggleField(schoolField, true);
            toggleField(typeField, true);
        } else {
            toggleField(schoolField, false);
            toggleField(typeField, false);
        }
    });

    $('select[name="cons_isee[type]"]').change(function () {

        const switchFields = $('#switch-fields');

        const type = this.value;
        var field;

        var fields = switchFields.find('select, input');
        for (var i = 0; i < fields.length; i++) {
            toggleField(fields[i], false);
        }

        switch (type) {
            case 'REGOLARE' :
                field = $('select[name="cons_isee[enrollmentYear]"]');
                updateFieldOptions(field);
                toggleField(field, true);
                break;
            case 'RIDOTTO' :
                field = $('select[name="cons_isee[compulsoryAttendance]"]');
                toggleField(field, true);
                break;
            case 'DOPPIA_ISCRIZIONE' :
            case 'CORSI_SINGOLI' :
            case 'PART_TIME' :
            case 'TALENT' :
                field = $('select[name="cons_isee[cfaRange]"]');
                updateFieldOptions(field);
                toggleField(field, true);
                break;
        }
    });

    $('select[name="cons_isee[enrollmentYear]"]').change(function () {

        const field = $('input[name="cons_isee[cfaEarned]"]');

        (this.value === "1" || this.value === "") ? toggleField(field, false) : toggleField(field, true);
    });

    $('input[name="cons_isee[noIsee]"]').change(function () {

        var field = $('input[name="cons_isee[isee]"]');
        this.checked ? toggleField(field, false) : toggleField(field, true);
    });
});



(function ($) {
    $.fn.extend({

        serializeJson: function (options) {

            const settings = $.extend({
                stringify: false
            }, options);

            let json = {};
            const array = this.serializeArray();

            for (let i = 0, len = array.length; i < len; i++) {
                let param = array[i];

                if (json[param.name]) {
                    if (!json[param.name].push)
                        json[param.name] = [json[param.name]];
                    json[param.name].push(param.value || '');
                } else
                    json[param.name] = param.value || '';
            }

            if (settings.stringify)
                return JSON.stringify(json);
            else
                return json;
        },
    })
})(jQuery);
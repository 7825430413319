(function ($) {
    $.fn.extend({
        initDateTimePicker: function () {

            var dDate = '';
            var orientation = {
                horizontal: 'auto',
                vertical: 'auto'
            };
            var parent = null;

            if (($(this).val() == '' && $(this).hasClass('default-today')))
                dDate = new Date();

            if (($(this).val() == '' && $(this).hasClass('orientation-right')))
                orientation.horizontal = "right";

            if (($(this).val() == '' && $(this).hasClass('orientation-left')))
                orientation.horizontal = "left";

            if (($(this).val() == '' && $(this).attr('data-widget-parent')))
                parent = $('#' + $(this).data('widget-parent'));

            var cLocale;

            if (typeof checkLocale === "function") {
                var cLocale = locale;
            } else {
                console.warn("Datetimepicker Lys component need Lys localization component to get correct localization properties. Fallback to 'it-IT'");
                cLocale = 'it-IT';
            }

            $(this).datetimepicker({
                locale: cLocale,
                useCurrent: false,
                format: 'DD/MM/YYYY',
                defaultDate: dDate,
                widgetPositioning: orientation,
                widgetParent: parent
            });

        }
    });

    $(document).ready(function () {
        $('.datepicker').each(function () {
            $(this).initDateTimePicker();
        });
    });

})
(jQuery);

(function ($) {

    // Panel collapse
    $('div.panel').find('a.collapse-link').click(function (e) {

        e.preventDefault();

        const panel = $(this).closest('.panel');

        // Set inline height to auto to start height transition when hide/show
        panel.css('height', 'auto');

        panel.find('div.panel-body').slideToggle('fast').promise().done(function () {
            panel.toggleClass('panel-collapsed');
            panel.css('height', '');
        });
    });

})(jQuery);

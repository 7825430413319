import 'bootstrap/dist/css/bootstrap.css';
import '../sass/main.scss';

/* JS */
import 'bootstrap';
import 'select2';
import './components/lys.localization.js';
import './components/lys.deletemodal.js';
import './components/lys.template.js';
import './components/lys.select2.remote.js';
import './components/lys.menu.js';
import './components/lys.panel.js';
import './components/lys.tooltip.js';
import './components/lys.progressbar.js';

import * as FontAwesome from './components/fontawesome';
import 'bootstrap/dist/css/bootstrap.css';
import 'datatables.net-src/css/dataTables.bootstrap4.scss';
import 'fullcalendar/dist/fullcalendar.css';
import 'fullcalendar-scheduler/dist/scheduler.css';
import '../sass/main.scss';

/* JS */
import 'jquery';
import 'jquery-ui';
import 'bootstrap';

import moment from 'moment';

moment.locale('it');

import 'fullcalendar';
import 'fullcalendar/dist/locale/it';
import 'fullcalendar-scheduler';
import 'select2';
import './components/lys.localization.js';
import './components/lys.datetimepicker.js';
import './components/lys.deletemodal.js';
import './components/lys.template.js';
import './components/lys.select2.remote.js';
import './components/lys.menu.js';
import './components/lys.panel.js';
import './components/lys.tooltip.js';
import './components/lys.progressbar.js';

import './components/common';
import * as Datatable from './components/datatable';

let table;

$(document).ready(function () {
    table = $('#users-datatable');

    let columns = [
        {data: "id"},
        {data: "firstName"},
        {data: "lastName"},
        {data: "email"},
        {data: "enabled"},
        {
            data: null,
            render: (data, type, row, meta) => {
                return `<button class="btn btn-xs btn-primary btn-update" data-enabled="${row.enabled}" data-id="${row.id}">${row.enabled ? 'Disattiva' : 'Attiva'}</button>`
            }
        }
    ];

    Datatable.init(table, {
        paging: true,
        info: false,
        ajax: (data, callback, settings) => {

            let params = {};

            params.firstname = $('#cons_search_users_firstName').val();
            params.lastname = $('#cons_search_users_lastName').val();
            params.email = $('#cons_search_users_email').val();

            params.limit = 10;
            params.offset = data.start;

            console.log(params);

            let route = Routing.generate(table.data("route"), params);

            $.get(route).done((data, status, request) => {
                callback({
                    recordsTotal: request.getResponseHeader("X-Total-Count"),
                    recordsFiltered: request.getResponseHeader("X-Total-Count"),
                    data: data
                });
            });
        },
        columns: columns
    });

    table.on('click', '.btn-update', e => {
        const $target = $(e.target);
        let id = $target.data('id');
        let enabled = $target.data('enabled');
        postUser({
            id: id,
            enabled: !enabled
        });
    });

    $(`form[name="cons_search_users"]`).keyup(
        delay(() => {
                table.DataTable().ajax.reload();
            },
            200));
});

function postUser(user) {

    let route = Routing.generate('post_user');

    $.ajax({
        url: route,
        data: user,
        type: 'POST',
        error: function (e) {
            alert("Impossibile aggiornare l\'utente");
        },
        success: function (result) {
            clear();
        }
    });
}

function clear() {
    table.DataTable().ajax.reload();
}

function delay(callback, ms) {
    let timer = 0;
    return function () {
        let context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            callback.apply(context, args);
        }, ms || 0);
    };
}
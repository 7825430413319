import 'mutationobserver-shim';

import {library, dom} from '@fortawesome/fontawesome-svg-core';

import {faHome} from '@fortawesome/free-solid-svg-icons/faHome';
import {faUser} from '@fortawesome/free-solid-svg-icons/faUser';
import {faCalculator} from '@fortawesome/free-solid-svg-icons/faCalculator';
import {faCalendar} from '@fortawesome/free-solid-svg-icons/faCalendar';
import {faList} from '@fortawesome/free-solid-svg-icons/faList';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {faBars} from '@fortawesome/free-solid-svg-icons/faBars';
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import {faChevronUp} from '@fortawesome/free-solid-svg-icons/faChevronUp';
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons/faCircleNotch';

library.add(
    faHome,
    faUser,
    faCalculator,
    faCalendar,
    faList,
    faTimes,
    faBars,
    faSignOutAlt,
    faChevronUp,
    faCircleNotch
);

dom.i2svg();
dom.watch();

export function addSpinner(btn) {
    let $btn = $(btn);

    $btn.attr('disabled', 'disabled');

    let $icon = $btn.find('svg');

    if (!$icon.length) {
        $icon = $(`<svg class="svg-inline--fa fa-circle-notch fa-w-16 fa-spin ml-05" aria-hidden="true" data-prefix="fas" data-icon="circle-notch" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184-101.689 0-184-82.295-184-184 0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"></path></svg>`);
        $btn.append($icon);
    } else {
        let prev = $icon.data('icon');
        $icon.attr('data-icon-prev', prev);
        $icon.attr('data-icon', 'circle-notch');
    }

    $icon.addClass('fa-spin');
}

export function removeSpinner(btn) {
    let $btn = $(btn);

    $btn.removeAttr('disabled');

    let $icon = $btn.find('svg');

    let prev = $icon.attr('data-icon-prev');

    if (prev) {
        $icon.attr('data-icon', prev);
        $icon.removeClass('fa-spin');
    } else
        $icon.remove();
}
/**
 * @author Loykos <andrea.moraglia@gmail.com>
 * @description localization functions
 */

checkLocale();

function checkLocale() {

    if (typeof locale === 'undefined') {
        console.log("Global variable 'locale' is not defined! Fallback to 'it-IT'");
        window.locale = 'it-IT';
    }

    try {
        // Check if locale is well formed
        Intl.getCanonicalLocales(locale);
    }
    catch (e) {
        console.log("Global variable 'locale' is not well formed. Fallback to 'it-IT'");
        window.locale = 'it-IT';
    }

}

function renderCurrency(val) {

    val = Number(val);

    if (isNaN(val))
        return;

    var options = {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    };

    return val.toLocaleString(locale, options);
}

function renderInteger(val) {
    val = Number(val);

    if (isNaN(val))
        return;

    val = Math.round(val);

    return val.toLocaleString(locale, {minimumFractionDigits: 0, maximumFractionDigits: 0})
}

function renderFloat(val) {

    val = Number(val);

    if (isNaN(val))
        return;

    return val.toLocaleString(locale, {minimumFractionDigits: 2, maximumFractionDigits: 2})
}


/**
 * @author Loykos <andrea.moraglia@gmail.com>
 * @description script to set "active" class on active <li> link element
 */
(function ($) {
    $(document).ready(function () {

        // Prevent link with "active" class
        $('ul.menu-list').find('li.active').removeClass('active');

        // Iterate over links element to check "active-routes" data attribute
        // or (by default) pathname component.
        // Pathname is the full href element without base root
        $('ul.menu-list').find('li > a').filter(function () {
            var routes = [];
            routes.push(this.pathname);

            if ($(this).data('active-routes'))
                routes.push($(this).data('active-routes'));

            return $.inArray(window.location.pathname, routes) !== -1;
        }).each(function () {
            $(this).parents('li').addClass('active');
            $(this).parents('li.dropdown').addClass('show');
        });

        $('ul.menu-list').find('li.dropdown a').click(function () {
            const parent = $(this).parent('li');
            parent.find('ul').toggle();
            parent.toggleClass('show');
        });
    });
})(jQuery);

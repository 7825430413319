import 'bootstrap/dist/css/bootstrap.css';
import 'datatables.net-src/css/dataTables.bootstrap4.scss';
import 'fullcalendar/dist/fullcalendar.css';
import 'fullcalendar-scheduler/dist/scheduler.css';
import '../sass/main.scss';

/* JS */
import 'jquery';
import 'jquery-ui';
import 'bootstrap';

import moment from 'moment';

moment.locale('it');

import 'fullcalendar';
import 'fullcalendar/dist/locale/it';
import 'fullcalendar-scheduler';
import 'select2';
import './components/lys.localization.js';
import './components/lys.datetimepicker.js';
import './components/lys.deletemodal.js';
import './components/lys.template.js';
import './components/lys.select2.remote.js';
import './components/lys.menu.js';
import './components/lys.panel.js';
import './components/lys.tooltip.js';
import './components/lys.progressbar.js';

import './components/common';
import * as Datatable from './components/datatable';
import * as DatePicker from './components/datepicker';
import * as FontAwesome from './components/fontawesome';

let table;
let calendar;
let addButton;
let addModalButton;
let editModalButton;
let eventModal;
let eventModalForm;
let $bookingForm;

function renderFullDate(data, type, row, meta) {
    return moment.utc(data).format('DD/MM/YYYY H:mm');
}

function renderDate(data, type, row, meta) {
    return moment.utc(data).format('DD/MM/YYYY');
}

function renderHours(data, type, row, meta) {
    return moment.utc(data).format('H:mm');
}

function renderEvents(events) {
    let html = "";

    for (let i = 0; i < events.length; i++) {
        let event = events[i];

        let day = renderDate(event.start);
        let startHour = renderHours(event.start);
        let endHour = renderHours(event.end);

        html += `<li>[${day}] Aula ${event.room.name}, ${startHour}/${endHour}${event.title ? ' - ' + event.title : ''}</li>`;
    }

    return html;
}

function postEvent(event) {

    let route = Routing.generate('post_event');

    $.ajax({
        url: route,
        data: event,
        type: 'POST',
        error: function (e) {
            alert('Impossibile aggiungere la prenotazione: ' + e.responseJSON.message);
        },
        success: function (result) {
            clear();
        }
    });
}

function postMultipleEvent(params) {

    let route = Routing.generate('post_multiple_event');

    return $.ajax({
        url: route,
        data: params,
        type: 'POST',
    });
}

function deleteEvent(id) {

    let route = Routing.generate('delete_event', {
        id: id
    });

    let r = confirm("Sei sicuro di voler eliminare la prenotazione?");

    if (r !== true)
        return;

    $.ajax({
        url: route,
        type: 'DELETE',
        error: function (e) {
            alert("Si è verificato un errore durante l'eliminazione della prenotazione" + e.responseJSON.message);
        },
        success: function () {
            clear();
        }
    });
}

function deleteMultipleEvent(params) {

    let route = Routing.generate('delete_multiple_event');

    return $.ajax({
        url: route,
        data: params,
        type: 'DELETE',
    });

}

function clear() {
    table.DataTable().ajax.reload();
    calendar.fullCalendar('refetchEvents');
    calendar.fullCalendar('unselect');
    eventModal.modal('hide');
    addButton.removeData('event');
    addModalButton.removeData('event');
    eventModalForm[0].reset();
    if ($bookingForm.length)
        $bookingForm[0].reset();
}

$(document).ready(function () {

    DatePicker.init($('#cons_booking_startDate'), {
        startDate: new Date(),
        endDate: null,
        daysOfWeekDisabled: [0]
    });
    DatePicker.init($('#cons_booking_endDate'), {
        startDate: new Date(),
        endDate: null,
        daysOfWeekDisabled: [0]
    });

    DatePicker.init($('#event-date'), {
        startDate: new Date(),
        endDate: null,
        daysOfWeekDisabled: [0]
    });

    DatePicker.init($('#current-date'), {
        startDate: new Date(),
        endDate: null,
        daysOfWeekDisabled: [0]
    });

    $('#current-date').change(() => {
        let date = $('#current-date').datepicker('getDate');
        calendar.fullCalendar('gotoDate', date);
    });

    table = $('#books-datatable');

    let columns = [
        {data: "room.name"},
        {
            data: "start",
            render: value => {
                return renderDate(value);
            }
        },
        {
            data: "end",
            render: (data, type, row, meta) => {
                return `${renderHours(row.start)}/${renderHours(row.end)}`
            }
        },
        {
            data: null,
            render: (data, type, row, meta) => {
                return `<button class="btn btn-xs btn-primary btn-delete" data-id="${row.id}">Elimina</button>`
            }
        }
    ];

    if (_isAdmin)
        columns.unshift({
            data: "title",
        });

    let $searchBookingName = $('#cons_search_booking_name');
    let $searchBookingRooms = $('#cons_search_booking_rooms');

    Datatable.init(table, {
        paging: true,
        info: false,
        ajax: (data, callback, settings) => {

            let params = {};

            if (_isAdmin) {

                params.name = $searchBookingName.val();
                params.room = $searchBookingRooms.val();

                if (!params.name && !params.room) {
                    callback({
                        recordsTotal: 0,
                        recordsFiltered: 0,
                        data: []
                    });
                    return;
                }

            } else {
                params.userId = _userId;
            }

            params.limit = 10;
            params.offset = data.start;

            let route = Routing.generate(table.data("route"), params);

            $.get(route).done((data, status, request) => {
                callback({
                    recordsTotal: request.getResponseHeader("X-Total-Count"),
                    recordsFiltered: request.getResponseHeader("X-Total-Count"),
                    data: data
                });
            });
        },
        columns: columns
    });

    table.on('click', '.btn-delete', e => {
        let id = $(e.target).data('id');
        deleteEvent(id);
    });

    $searchBookingName.keyup(() => {
        table.DataTable().ajax.reload();
    });

    $searchBookingRooms.change(() => {
        table.DataTable().ajax.reload();
    });

    calendar = $('#calendar');
    addButton = $('#add');
    addModalButton = $('#add-modal');
    editModalButton = $('#edit-modal');
    eventModal = $('#event-modal');
    eventModalForm = $('#event-modal-form');

    $bookingForm = $('#booking-submit').closest('form');

    $('#select-all').on('change', function () {
        $bookingForm.find(`[name="cons_booking[rooms][]"]`).attr('checked', $(this).is(':checked'));
    });

    $bookingForm.find('button[type="submit"]').click(e => {
        e.preventDefault();

        let add = e.target.id === "booking-submit";

        if ($bookingForm[0].checkValidity()) {
            let data = $bookingForm.serializeJson();

            let regex = /cons_booking\[([a-zA-Z-_]*)]/;
            let params = {};
            let keys = Object.keys(data);
            for (let i = 0; i < keys.length; i++) {
                let arr = regex.exec(keys[i]);
                params[arr[1]] = data[keys[i]];
            }

            if (params.startDate === params.endDate) {
                alert("Inserire una data di fine diversa da quella di inizio.");
                return;
            }

            if (params.startHour === params.endHour) {
                alert("Inserire un'ora di fine diversa da quella di inizio.");
                return;
            }

            params.startDate = moment(params.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
            params.endDate = moment(params.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

            $('#booking-mutliple-confirm').data(params);

            if (add) {
                postMultipleEvent(params).done(events => {
                    $('#booking-mutliple-confirm').data('params', params);
                    $('#booking-multiple-modal').modal('show');

                    $('#booking-multiple-type').html(
                        `<i class="square event-type-${events[0].event_type.id}"></i>&nbsp;<span>${events[0].event_type.name}</span>`);

                    $('#booking-multiple-events').html(renderEvents(events));

                }).fail(error => console.error(error));
            } else {
                deleteMultipleEvent(params).done(events => {
                    $('#delete-mutliple-confirm').data('params', params);
                    $('#delete-multiple-modal').modal('show');

                    $('#delete-multiple-events').html(renderEvents(events));

                }).fail(error => console.error(error));
            }
        }
    });

    $('#booking-mutliple-confirm').click(function () {

        let $btn = $(this);

        let params = $('#booking-mutliple-confirm').data('params');
        params.execute = true;

        FontAwesome.addSpinner($btn);

        postMultipleEvent(params).done(() => {
            clear();
            $('#booking-multiple-modal').modal('hide');
            FontAwesome.removeSpinner($btn);
        }).fail(error => console.error(error));
    });

    $('#delete-mutliple-confirm').click(function () {

        let $btn = $(this);

        let params = $('#delete-mutliple-confirm').data('params');
        params.execute = true;

        FontAwesome.addSpinner($btn);

        deleteMultipleEvent(params).done(() => {
            clear();
            $('#delete-multiple-modal').modal('hide');
            FontAwesome.removeSpinner($btn);
        }).fail(error => console.error(error));
    });

    addButton.click(function () {
        let event = $(this).data('event');
        loadModal(event);
    });

    let loadModal = event => {

        if (event) {
            $('#event-date').datepicker('setDate', moment(event.start).startOf('day').toDate());
            $('#event-room').val(event.room);
            $('#event-hour-start').val(renderHours(event.start));
            $('#event-hour-end').val(renderHours(event.end));
            $('#event-name').val(null);
            $("#event-type").val($("#event-type option:first").val());
        }

        if (event && event.id) {
            $('#event-name').val(event.title);
            $('#event-room').val(event.room.id);
            $('#event-type').val(event.event_type.id);

            editModalButton.data('id', event.id);
            editModalButton.show();
            addModalButton.hide();
        } else {
            addModalButton.show();
            editModalButton.hide();
        }

        eventModal.modal('show');
    };

    eventModalForm.find('[type="button"]').click(function (e) {
        e.preventDefault();

        let $btn = $(e.target);

        let id = $btn.data('id');

        let data = eventModalForm.serializeJson();

        if (!data.date) {
            $('#form-error').html('Selezionare una data');
            setTimeout(() => {
                $('#form-error').html('');
            }, 5000);
            return;
        }

        let startDate = moment(`${data.date} ${data.hourStart}`, 'DD/MM/YYYY H:mm');
        let endDate = moment(`${data.date} ${data.hourEnd}`, 'DD/MM/YYYY H:mm');

        if (endDate.diff(startDate, 'minutes') <= 0) {
            $('#form-error').html('Selezionare un ora di fine maggiore di quella di inizio');
            setTimeout(() => {
                $('#form-error').html('');
            }, 5000);
            return;
        }

        if (!_isAdmin && endDate.diff(startDate, 'minutes') > 120) {
            $('#form-error').html('La durata massima della prenotazione è di 2 ore');
            setTimeout(() => {
                $('#form-error').html('');
            }, 5000);
            return;
        }

        if (!_isAdmin && endDate.diff(startDate, 'minutes') < 30) {
            $('#form-error').html('La durata minima della prenotazione è di 30 minuti');
            setTimeout(() => {
                $('#form-error').html('');
            }, 5000);
            return;
        }

        let event = {
            start: startDate.format('YYYY-MM-DD HH:mm'),
            end: endDate.format('YYYY-MM-DD HH:mm'),
            eventType: _isAdmin ? data.eventType : 1,
            room: data.room,
            name: data.name
        };

        if (_isAdmin && id)
            event.id = id;

        postEvent(event);
    });

    let businessHours = {
        // days of week. an array of zero-based day of week integers (0=Sunday)
        dow: [1, 2, 3, 4, 5, 6], // Monday - Thursday

        start: '08:00', // a start time (10am in this example)
        end: '20:00' // an end time (6pm in this example)
    };

    calendar.fullCalendar({
        customButtons: {
            myCustomButton: {
                text: 'custom!',
                click: function () {
                    alert('clicked the custom button!');
                }
            }
        },
        header: {
            left: '',
            center: '',
            right: 'prev,next' //'title prev,next'
        },
        contentHeight: 'auto',
        locale: 'it',
        minTime: "08:00:00",
        maxTime: "20:00:00",
        select: function (start, end, jsEvent, view, resource) {

            let event = {
                start: start.format(),
                end: end.format(),
                room: resource.id
            };

            addButton.data('event', event);

            if (!_isAdmin) {
                let duration = moment.duration(end.diff(start));
                if (duration.asHours() > 2) {
                    let d = start.clone();
                    d.add(2, 'h');
                    calendar.fullCalendar('select', start, d, resource.id);
                }
            }
        },
        /* selectAllow: function (event) {
             let duration = moment.duration(event.end.diff(event.start));
             let hours = duration.asHours();
             return hours <= 2;
         },*/
        businessHours: businessHours,
        selectConstraint: businessHours,
        selectOverlap: _isAdmin,
        unselectAuto: false,
        navLinks:
            true, // can click day/week names to navigate views
        selectable:
            true,
        eventRender:
            (event, element) => {
                if (_isAdmin) {
                    let closeon = $('<span class="closeon"><i class="fas fa-times"></i></span>');
                    element.append(closeon);

                    closeon.click(e => {
                        e.preventDefault();
                        e.stopImmediatePropagation();
                        deleteEvent(event.id);
                    });

                    element.click(() => loadModal(event));

                } else if (event.user && event.user.id == _userId) {
                    element.addClass("event-book");
                }
                element.addClass("event-type-" + event.event_type.id);
            },
        eventClick: function (calEvent, jsEvent, view) {
            $(this).toggleClass('selected');
        },
        eventLimit: true, // allow "more" link when too many events
        events:

            function (start, end, timezone, callback) {

                let route = Routing.generate('get_events', {
                    start: start.format('YYYY-MM-DD'),
                    end: end.format('YYYY-MM-DD')
                });

                $.ajax({
                    url: route,
                    type: 'GET',
                    dataType: 'json',
                    error: function (e) {
                        alert("Si è verificato un errore nel recupero degli eventi:" + e.responseJSON.message);
                    },
                    success: function (data) {

                        let resources_route = Routing.generate('get_rooms');

                        $.ajax({
                            url: resources_route,
                            type: 'GET',
                            dataType: 'json',
                            error: function () {
                                alert("Si è verificato un errore nel recupero delle aule:" + e.responseJSON.message);
                            },
                            success: function (rooms) {
                                data.push({
                                    title: "background",
                                    dow: [7], //sunday
                                    rendering: 'background',
                                    start: "07:00:00",
                                    end: "08:00:00",
                                    resourceIds: $.map(rooms, function (item) {
                                        return item.id
                                    })
                                });
                                callback(data);
                            }
                        });


                    }
                });
            },
        defaultView: 'timelineDay',
        views:
            {
                timelineDay: {
                    titleFormat: 'DD/MM/YYYY',
                    slotLabelFormat: ['H:mm']
                },
                /*settimana: {
                    type: 'agendaWeek',
                    duration:
                        {
                            days: 7
                        },
                    title: 'Apertura',
                    columnFormat:
                        'dddd', // Format the day to only show like 'Monday'
                    hiddenDays:
                        [0, 6] // Hide Sunday and Saturday?
                }*/
            }
        ,
        viewRender: function (currentView) {
            let minDate = moment();
            // Past
            if (minDate >= currentView.start && minDate <= currentView.end) {
                $(".fc-prev-button").prop('disabled', true);
                $(".fc-prev-button").addClass('fc-state-disabled');
            } else {
                $(".fc-prev-button").removeClass('fc-state-disabled');
                $(".fc-prev-button").prop('disabled', false);
            }

            $('#current-date').datepicker('setDate', currentView.start.startOf('day').toDate());
        },
        resourceColumns: [
            {
                labelText: 'Aula',
                field: 'name',
                width: 150
            }
        ],
        resources:

            function (callback, start, end, timezone) {

                let route = Routing.generate('get_rooms');

                $.ajax({
                    url: route,
                    type: 'GET',
                    dataType: 'json',
                    error: function (e) {
                        alert('Si è verificato un errore durante il recupero delle aule:' + e.responseJSON.message);
                    },
                    success: function (data) {

                        let $roomsList = $('#rooms-modal').find('ul');

                        $roomsList.html('');
                        $('#event-room').html('');

                        for (let i = 0; i < data.length; i++) {
                            $('#event-room').append(`<option value="${data[i].id}">${data[i].description}</option>`);
                            $roomsList.append(`<li><strong>${data[i].name}:</strong> ${data[i].description}</li>`);
                        }


                        callback(data);
                    }
                });
            },
        groupByDateAndResource: true,
        schedulerLicenseKey:
            'CC-Attribution-NonCommercial-NoDerivatives'
    });

});

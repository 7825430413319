import 'bootstrap-datepicker';
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.it.min';
import '../../sass/vendors/_datepicker.scss';

export function init(selector, opts) {

    let defOpts = {
        autoclose: true,
        language: 'it',
        startDate: new Date(0),
        endDate : 'today'
    };

    let params = $.extend({}, defOpts, opts);

    $(selector).datepicker(params);

    if (params.default)
        $(selector).datepicker('setDate', params.default);

}

export function link(selector1, selector2) {

    let $selector1 = $(selector1);
    let $selector2 = $(selector2);

    $selector1.on('changeDate', datepicker => {

        let date = new Date(datepicker.date.valueOf());
        let endDate = $selector2.datepicker('getDate');

        if (endDate && endDate < date)
            $selector2.datepicker('setDate', date);
    });

    $selector2.on('changeDate', datepicker => {

        let date = new Date(datepicker.date.valueOf());
        let startDate = $selector1.datepicker('getDate');

        if (startDate && startDate > date)
            $selector1.datepicker('setDate', date);
    });
}